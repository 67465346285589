












import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import Correct from '@/components/modules/drills/organisms/Correct.vue'
import DrillWebApi from '@/mixins/drills/WebApi'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import StudentBottomPanel from '@/components/organisms/StudentBottomPanel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    SidebarSwitcher,
    Correct,
    StudentBottomPanel,
    ButtonBase,
  },
})
export default class DrillResult extends Mixins(DrillWebApi, ClassModeChangeAble) {
  @Prop({ default: 0 })
  resultDrillId!: number

  private isLoaded = false

  private classCategoryCode = ''

  private duration = 0

  private get correctParams() {
    return { currentPageType: 'result_all', classCategoryCode: this.classCategoryCode }
  }

  private get classCategoryText() {
    const classCategorys = { EN: '演習', SY: '宿題', RK: '理解度テスト' }
    return classCategorys[this.classCategoryCode]
  }

  private get durationText() {
    return `${Math.floor(this.duration / 60)}分`
  }

  protected async mounted(): Promise<any> {
    Vue.prototype.$loading.start()
    await this.checkClassModeAndSideMenuMode()
    await this.startResultAll(this.resultDrillId)
    Vue.prototype.$loading.complete()
    this.isLoaded = true
  }

  protected async startResultAll(resultDrillId: number): Promise<any> {
    const params = { resultDrillId: resultDrillId, isOnlyShow: true }
    this.$store.commit('drills/setProcessed', false)

    let result: any
    try {
      result = await Vue.prototype.$http.httpWithToken.post('/drill/result-all-start', params)
    } catch {
      throw 'Drill Error!'
    }
    this.setSubjectCode(result.data.subjectCode)
    this.classCategoryCode = result.data.classCategoryCode
    this.duration = result.data.duration

    // ドリルデータをストアに格納する
    this.$store.dispatch('drills/setDrillData', result.data)

    // ドリルに関連する画像を取得する
    await this.imagePaths()

    // 読み込み完了
    this.$store.commit('drills/setProcessed', true)
  }

  private async setSubjectCode(code: string) {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['subjectCode'] = code
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  private async movePrev() {
    const isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
    const name = Array.isArray(this.$route.query.prevPage)
      ? this.$route.query.prevPage[0] || ''
      : this.$route.query.prevPage || ''
    const id = Array.isArray(this.$route.query.prevId)
      ? this.$route.query.prevId[0] || ''
      : this.$route.query.prevId || ''
    const learningType = Array.isArray(this.$route.query.prevType)
      ? this.$route.query.prevType[0] || ''
      : this.$route.query.prevType || ''
    const prevPage = Array.isArray(this.$route.query.beforePage)
      ? this.$route.query.beforePage[0] || ''
      : this.$route.query.beforePage || ''
    const route = { name: name }
    const targetQueryKeys = ['selectedSubject', 'modeCode']
    const targetQuery = {}
    for (const [key, value] of Object.entries(this.$route.query)) {
      if (targetQueryKeys.includes(key)) targetQuery[key] = value
    }
    if (prevPage) targetQuery['prevPage'] = prevPage
    if (id && isV3) {
      route['params'] = { id: id }
    }
    if (id && learningType) {
      route['params'] = { id: id, learningType: learningType }
    }
    if (Object.keys(targetQuery).length > 0) route['query'] = targetQuery
    this.$router.push(route)
  }
}
